import { FC, lazy, Suspense } from 'react'
import { Route, RouteProps, Routes as Switch } from 'react-router-dom'

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}

type AppRoute = RouteProps & {
  type?: RouteType
}

const Home = lazy(() => import('pages/Home'))
const ItemsAdd = lazy(() => import('pages/Items/Add'))
const ItemsEdit = lazy(() => import('pages/Items/Edit'))
const DisplayCooling = lazy(() => import('pages/Coolings'))
const WorkingOrder = lazy(() => import('pages/WorkingOrders/index'))
const Page404 = lazy(() => import('pages/Page404'))

export const AppRoutes: AppRoute[] = [
  {
    type: RouteType.PUBLIC,
    path: '/',
    children: <Home />,
  },
  {
    type: RouteType.PUBLIC,
    path: '/newItem',
    children: <ItemsAdd />,
  },
  {
    type: RouteType.PUBLIC,
    path: '/editItem/:id',
    children: <ItemsEdit />,
  },
  {
    type: RouteType.PUBLIC,
    path: '/shop',
    children: <DisplayCooling />,
  },
  {
    type: RouteType.PUBLIC,
    path: '/workingOrders',
    children: <WorkingOrder />,
  },
  // 404 Error
  {
    type: RouteType.PUBLIC,
    path: '*',
    children: <Page404 />,
  },
]

const Routes: FC = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {AppRoutes.map((r) => {
          return (
            <Route key={`${r.path}`} path={`${r.path}`} element={r.children} />
          )
        })}
      </Switch>
    </Suspense>
  )
}

export default Routes
